<template>
  <v-card flat :loading="loading">
    <v-row v-if="[...moduleRoles].length === 0" class="justify-space-around">
      <v-alert shaped dense type="info">
        <strong>Must</strong> activate a <strong>module</strong> to be able to add users
      </v-alert>
    </v-row>
    <v-card-title>
      Users Matrix

      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <tenant-user-form-dialog
        ref="tenant_users_dialog"
        id="tenant_users_dialog"
        :type="this.type"
      ></tenant-user-form-dialog>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-data-table :headers="headers" :items="currentTenantUsers" :search="search">
        <template v-slot:item.first_name="{ item }">
          <v-btn
            plain
            text
            @click="editUserTenant(item)"
            class="text-capitalize"
            color="blue darken-4"
          >
            {{ item.first_name }} {{ item.last_name }}
            {{ item.second_last_name }}
          </v-btn>
        </template>
        <template v-slot:item.active="{ item }">
          <v-icon v-if="item.active" color="green">
            mdi-checkbox-marked-outline
<!--            mdi-check-->
          </v-icon>
          <v-icon v-else color="red">
            mdi-close-box-outline
<!--            mdi-close-->
          </v-icon>
        </template>
        <template v-slot:item.can_receive_email="{ item }">
          <v-icon v-if="item.can_receive_email" color="green">
            mdi-check
          </v-icon>
          <v-icon v-else color="red">
            mdi-close
          </v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TenantUserFormDialog from '../tenant/TenantUserFormDialog.vue';
export default {
  /************************************************************************************
   *                                    COMPONENTS
   * **********************************************************************************/
  components: { TenantUserFormDialog },

  /************************************************************************************
   *                                    PROPS
   * **********************************************************************************/
  props: ['type'],

  /************************************************************************************
   *                                    DATA
   * **********************************************************************************/
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          class: "grey lighten-4 text-uppercase",
          align: "start",
          text: "Email",
          value: "email",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Full name",
          value: "first_name",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Position",
          value: "position",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Phone number",
          value: "phone_number",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Active",
          value: "active",
        },
        {
          class: "grey lighten-4 text-uppercase",
          align: "center",
          text: "Receive Notifications",
          value: "can_receive_email",
        },
      ],
    };
  },

  /************************************************************************************
   *                                    COMPUTED
   * **********************************************************************************/
  computed: {
    ...mapGetters(["currentTenantUsers", "currentUserType", "currentTenant", "moduleRoles"]),

    currentRouteName() {
      return this.$route.name;
    },
  },

  /************************************************************************************
   *                                    ACTIONS
   * **********************************************************************************/
  actions: {

  },

  /************************************************************************************
   *                                    METHODS
   * **********************************************************************************/
  methods: {
    ...mapActions(["getTenantsUsers", "clearTenantUsers"]),

    editUserTenant(user = null) {
      if (!user) return;

      const self = this;
      self.$refs.tenant_users_dialog.open(user);
    },
  },

  /************************************************************************************
   *                                    WATCH
   * **********************************************************************************/
  watch: {

  },

  /************************************************************************************
   *                                    CREATED
   * **********************************************************************************/
  created() {
    const self = this;
    const userType = self.currentUserType.key;

    if (!self.currentTenant && userType == "master_admin") {
      //Pedir que seleccione un tenant
      return
    }

    const param = this.$route.params;

    if (param.tenant_id || param.supplier_id) {
      self.loading = true;

      self.getTenantsUsers(self.currentTenant).finally(() => {
        self.loading = false;
      });
    } else{
      self.$store.commit('clearTenantUsers');
    }
  },

  /************************************************************************************
   *                                  MOUNTED
   * **********************************************************************************/
  mounted() {

  },
};
</script>

<style>

</style>
